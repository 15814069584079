/******************** COLORES *******************************/
:root {
	--blanco: #ffffff;
	--negro: #1d1e1e;
	--footer1: #1d1e1e;
	--footer2: #262a2a;
	--enviado: #55b08a;
	--spam: #d12046;

	--gris1: #eaf4ff;
	--principal: #1e335b;
	--secundario: #21abb2;
}
.bg-blanco{ background-color: var(--blanco); }
.bg-negro{ background-color: var(--negro); }
.bg-footer-1{ background-color: var(--footer1); }
.bg-footer-2{ background-color: var(--footer2); }
.bg-enviado{ background-color: var(--enviado); }
.bg-spam{ background-color: var(--spam); }

.bg-gris-1{ background-color: var(--gris1); }
.bg-principal{ background-color: var(--principal); }
.bg-secundario{ background-color: var(--secundario); }

.t-blanco{ color: var(--blanco); !important;}
.t-negro{ color: var(--negro); !important;}


/******************** EXTRA PADDINGS *******************************/
.pt-1{padding-top: 5px !important; }
.pt-2{padding-top: 10px !important; }
.pt-3{padding-top: 15px !important; }
.pt-4{padding-top: 30px !important; }
.pt-5{padding-top: 40px !important; }
.pt-6{padding-top: 50px !important; }
.pt-7{padding-top: 70px !important; }
.pt-8{padding-top: 90px !important; }

.pb-1{padding-bottom: 5px !important; }
.pb-2{padding-bottom: 10px !important; }
.pb-3{padding-bottom: 15px !important; }
.pb-4{padding-bottom: 30px !important; }
.pb-5{padding-bottom: 40px !important; }
.pb-6{padding-bottom: 50px !important; }
.pb-7{padding-bottom: 70px !important; }
.pb-8{padding-bottom: 90px !important; }

.pl-1{padding-left: 5px !important; }
.pl-2{padding-left: 10px !important; }
.pl-3{padding-left: 15px !important; }
.pl-4{padding-left: 30px !important; }
.pl-5{padding-left: 40px !important; }
.pl-6{padding-left: 50px !important; }
.pl-7{padding-left: 70px !important; }
.pl-8{padding-left: 90px !important; }

.pr-1{padding-right: 5px !important; }
.pr-2{padding-right: 10px !important; }
.pr-3{padding-right: 15px !important; }
.pr-4{padding-right: 30px !important; }
.pr-5{padding-right: 40px !important; }
.pr-6{padding-right: 50px !important; }
.pr-7{padding-right: 70px !important; }
.pr-8{padding-right: 90px !important; }

/******************** EXTRA PADDINGS *******************************/
.py-1{padding-top: 5px !important; padding-bottom: 5px !important}
.py-2{padding-top: 10px !important; padding-bottom: 10px !important }
.py-3{padding-top: 15px !important; padding-bottom: 15px !important }
.py-4{padding-top: 30px !important; padding-bottom: 30px !important }
.py-5{padding-top: 40px !important;; padding-bottom: 40px !important;;}
.py-6{padding-top: 50px !important;; padding-bottom: 50px !important;;}
.py-7{padding-top: 70px !important;; padding-bottom: 70px !important;;}
.py-8{padding-top: 90px !important;; padding-bottom: 90px !important;;}

.my-1{margin-top: 5px !important; margin-bottom: 5px !important}
.my-2{margin-top: 10px !important; margin-bottom: 10px !important }
.my-3{margin-top: 15px !important; margin-bottom: 15px !important }
.my-4{margin-top: 30px !important; margin-bottom: 30px !important }
.my-5{margin-top: 40px !important;; margin-bottom: 40px !important;;}
.my-6{margin-top: 50px !important;; margin-bottom: 50px !important;;}
.my-7{margin-top: 70px !important;; margin-bottom: 70px !important;;}
.my-8{margin-top: 90px !important;; margin-bottom: 90px !important;;}

/******************** EXTRA PADDINGS X *******************************/
.px-0{padding-left: 0px; padding-right: 0px;}
.px-1{padding-left: 5px; padding-right: 5px;}
.px-2{padding-left: 10px; padding-right: 10px;}
.px-3{padding-left: 15px; padding-right: 15px;}
.px-4{padding-left: 25px; padding-right: 25px;}
.px-5{padding-left: 35px; padding-right: 35px;}

/******************** EXTRA MARGIN X *******************************/
.mx-0{margin-left: 0px; margin-right: 0px;}
.mx-1{margin-left: 5px; margin-right: 5px;}
.mx-2{margin-left: 10px; margin-right: 10px;}
.mx-3{margin-left: 15px; margin-right: 15px;}
.mx-4{margin-left: 25px; margin-right: 25px;}
.mx-5{margin-left: 35px; margin-right: 35px;}


/******************** EXTRA MARGINS *******************************/
.mt-1{margin-top: 5px !important; }
.mt-2{margin-top: 10px !important; }
.mt-3{margin-top: 15px !important; }
.mt-4{margin-top: 30px !important; }
.mt-5{margin-top: 40px !important; }
.mt-6{margin-top: 50px !important; }
.mt-7{margin-top: 70px !important; }
.mt-8{margin-top: 90px !important; }

.mb-1{margin-bottom: 5px !important; }
.mb-2{margin-bottom: 10px !important; }
.mb-3{margin-bottom: 15px !important; }
.mb-4{margin-bottom: 30px !important; }
.mb-5{margin-bottom: 40px !important; }
.mb-6{margin-bottom: 50px !important; }
.mb-7{margin-bottom: 70px !important; }
.mb-8{margin-bottom: 90px !important; }

/******************** QUITAR PADDINGS *******************************/
.ml-0{ margin-left: 0px; }
.mr-0{ margin-right: 0px; }
.pl-0{ padding-left: 0px; }
.pr-0{ padding-right: 0px; }
.pb-0{ padding-bottom: 0px; }
.pt-0{ padding-top: 0px; }
.mb-0{ margin-bottom: 0px; }
.mt-0{ margin-top: 0px; }

/******************* NEGATIVOS ********************/
.neg-top-125{ margin-top: -125px; }
.neg-top-80{ margin-top: -80px; }
.neg-top-70{ margin-top: -70px; }
.neg-top-60{ margin-top: -60px; }
.neg-top-50{ margin-top: -50px; }
.neg-top-40{ margin-top: -40px; }
.neg-top-30{ margin-top: -30px; }
.neg-top-25{ margin-top: -25px; }
.neg-top-20{ margin-top: -20px; }
.neg-top-5{ margin-top: -5px; }

.neg-bot-125{ margin-bottom: -125px; }
.neg-bot-80{ margin-bottom: -80px; }
.neg-bot-70{ margin-bottom: -70px; }
.neg-bot-60{ margin-bottom: -60px; }
.neg-bot-50{ margin-bottom: -50px; }
.neg-bot-40{ margin-bottom: -40px; }
.neg-bot-30{ margin-bottom: -30px; }
.neg-bot-27{ margin-bottom: -27px; }
.neg-bot-25{ margin-bottom: -25px; }
.neg-bot-20{ margin-bottom: -20px; }
.neg-bot-5{ margin-bottom: -5px; }

.neg-left-2{ margin-left: -2px;}
.neg-left-5{ margin-left: -5px;}
.neg-left-10{ margin-left: -10px;}
.neg-left-20{ margin-left: -20px;}
.neg-left-30{ margin-left: -30px;}
.neg-left-50{ margin-left: -50px;}
.neg-left-100{ margin-left: -100px;}

.neg-right-2{ margin-right: -2px;}
.neg-right-5{ margin-right: -5px;}
.neg-right-10{ margin-right: -10px;}
.neg-right-20{ margin-right: -20px;}
.neg-right-30{ margin-right: -30px;}

/******************* TOP BOT LEFT RIGHT 0 ********************/
.top-0 { top: 0px;}
.top-10 { top: 10px;}
.top-15 { top: 15px;}
.right-0 { right: 0px;}
.right-10 { right: 10px;}
.right-15 { right: 15px;}
.bottom-0 { bottom: 0px;}
.bottom-10 { bottom: 10px;}
.bottom-15 { bottom: 15px;}
.left-0 { left: 0px;}
.left-10 { left: 10px;}
.left-15 { left: 15px;}

/******************* Z INDEX ********************/
.z-1{ z-index: 1; }
.z-2{ z-index: 2; }
.z-3{ z-index: 3; }
.z-4{ z-index: 4; }
.z-5{ z-index: 5; }

/******************** TAMAÑOS TEXTOS *******************************/
.fs-10{ font-size: 10px !important; }
.fs-11{ font-size: 11px !important; }
.fs-12{ font-size: 12px !important; }
.fs-13{ font-size: 13px !important; }
.fs-14{ font-size: 14px !important; }
.fs-15{ font-size: 15px !important; }
.fs-16{ font-size: 16px !important; }
.fs-17{ font-size: 17px !important; }
.fs-18{ font-size: 18px !important; }
.fs-20{ font-size: 20px !important; }
.fs-22{ font-size: 22px !important; }
.fs-25{ font-size: 25px !important; }
.fs-30{ font-size: 30px !important; }
.fs-35{ font-size: 35px !important; }
.fs-40{ font-size: 40px !important; }
.fs-50{ font-size: 50px !important; }
.fs-60{ font-size: 60px !important; }


/******************** ANCHOS TEXTOS *******************************/
.fw-300{ font-weight: 300; }
.fw-400{ font-weight: 400; }
.fw-500{ font-weight: 500; }
.fw-600{ font-weight: 600; }
.fw-700{ font-weight: 700; }
.fw-800{ font-weight: 800; }

.min-vh-100 { min-height: 100vh !important; }
.min-vh-90 { min-height: 90vh !important; }
.min-vh-80 { min-height: 80vh !important; }
.min-vh-75 { min-height: 75vh !important; }
.min-vh-70 { min-height: 70vh !important; }
.min-vh-60 { min-height: 60vh !important; }
.min-vh-50 { min-height: 50vh !important; }
.min-vh-40 { min-height: 40vh !important; }
.min-vh-30 { min-height: 30vh !important; }
.min-vh-20 { min-height: 30vh !important; }
.min-vh-10 { min-height: 30vh !important; }

.min-h-50{ min-height: 50px; }
.min-h-75{ min-height: 75px; }
.min-h-100{ min-height: 100px; }
.min-h-125{ min-height: 125px; }
.min-h-150{ min-height: 150px; }
.min-h-175{ min-height: 175px; }
.min-h-185{ min-height: 185px; }
.min-h-200{ min-height: 200px; }
.min-h-225{ min-height: 225px; }
.min-h-250{ min-height: 250px; }
.min-h-300{ min-height: 300px; }
.min-h-350{ min-height: 350px; }
.min-h-400{ min-height: 400px; }
.min-h-450{ min-height: 450px; }
.min-h-500{ min-height: 500px; }
.min-h-600{ min-height: 600px; }
.min-h-700{ min-height: 700px; }
.min-h-800{ min-height: 800px; }

.max-h-70{ max-height: 70px; }
.max-h-100{ max-height: 100px; }
.max-h-125{ max-height: 125px; }
.max-h-150{ max-height: 150px; }
.max-h-175{ max-height: 175px; }
.max-h-200{ max-height: 200px; }
.max-h-225{ max-height: 225px; }
.max-h-250{ max-height: 250px; }
.max-h-300{ max-height: 300px; }
.max-h-350{ max-height: 350px; }
.max-h-400{ max-height: 400px; }
.max-h-450{ max-height: 450px; }
.max-h-500{ max-height: 500px; }
.max-h-600{ max-height: 600px; }
.max-h-700{ max-height: 700px; }
.max-h-800{ max-height: 800px; }

.max-w-35{ max-width: 35px !important;}
.max-w-40{ max-width: 40px !important;}
.max-w-50{ max-width: 50px !important;}
.max-w-65{ max-width: 65px !important;}
.max-w-75{ max-width: 75px !important;}
.max-w-100{ max-width: 100px !important;}
.max-w-125{ max-width: 125px !important;}
.max-w-150{ max-width: 150px !important;}
.max-w-175{ max-width: 175px !important;}
.max-w-200{ max-width: 200px !important;}
.max-w-225{ max-width: 225px !important;}
.max-w-250{ max-width: 250px !important;}
.max-w-300{ max-width: 300px !important;}
.max-w-350{ max-width: 350px !important;}
.max-w-400{ max-width: 400px !important;}
.max-w-450{ max-width: 450px !important;}
.max-w-500{ max-width: 500px !important;}
.max-w-600{ max-width: 600px !important;}
.max-w-700{ max-width: 700px !important;}
.max-w-800{ max-width: 800px !important;}
.max-w-900{ max-width: 900px !important;}
.max-w-1000{ max-width: 1000px !important;}
.max-w-1100{ max-width: 1100px !important;}
.max-w-1200{ max-width: 1200px !important;}

.min-w-35{ min-width: 35px !important;}
.min-w-40{ min-width: 40px !important;}
.min-w-50{ min-width: 50px !important;}
.min-w-75{ min-width: 75px !important;}
.min-w-100{ min-width: 100px !important;}
.min-w-125{ min-width: 125px !important;}
.min-w-150{ min-width: 150px !important;}
.min-w-175{ min-width: 175px !important;}
.min-w-200{ min-width: 200px !important;}
.min-w-225{ min-width: 225px !important;}
.min-w-250{ min-width: 250px !important;}
.min-w-300{ min-width: 300px !important;}
.min-w-350{ min-width: 350px !important;}
.min-w-400{ min-width: 400px !important;}
.min-w-450{ min-width: 450px !important;}
.min-w-500{ min-width: 500px !important;}
.min-w-600{ min-width: 600px !important;}
.min-w-700{ min-width: 700px !important;}
.min-w-800{ min-width: 800px !important;}
.min-w-900{ min-width: 900px !important;}
.min-w-1000{ min-width: 1000px !important;}
.min-w-1200{ min-width: 1200px !important;}

.w-80{ max-width: 80%;}
.w-85{ max-width: 85%;}
.w-90{ max-width: 90%;}
.w-95{ max-width: 95%;}
.w-100{ max-width: 100%;}

.rounded-100{ border-radius: 100% !important; }
.rounded-15{ border-radius: 15px !important; }
.rounded-10{ border-radius: 10px !important; }
.rounded-5{ border-radius: 5px !important; }
.rounded-3{ border-radius: 3px !important; }

.icono-5{ width: 5px !important; height: 5px !important; min-width: 5px !important; max-width: 5px !important; min-height: 5px !important; max-height: 5px !important; }
.icono-10{ width: 10px !important; height: 10px !important; min-width: 10px !important; max-width: 10px !important; min-height: 10px !important; max-height: 10px !important; }
.icono-15{ width: 15px !important; height: 15px !important; min-width: 15px !important; max-width: 15px !important; min-height: 15px !important; max-height: 15px !important; }
.icono-20{ width: 20px !important; height: 20px !important; min-width: 20px !important; max-width: 20px !important; min-height: 20px !important; max-height: 20px !important; }
.icono-25{ width: 25px !important; height: 25px !important; min-width: 25px !important; max-width: 25px !important; min-height: 25px !important; max-height: 25px !important;}
.icono-30{ width: 30px !important; height: 30px !important; min-width: 30px !important; max-width: 30px !important; min-height: 30px !important; max-height: 30px !important; }
.icono-35{ width: 35px !important; height: 35px !important; min-width: 35px !important; max-width: 35px !important; min-height: 35px !important; max-height: 35px !important; }
.icono-40{ width: 40px !important; height: 40px !important; min-width: 40px !important; max-width: 40px !important; min-height: 40px !important; max-height: 40px !important; }
.icono-45{ width: 45px !important; height: 45px !important; min-width: 45px !important; max-width: 45px !important; min-height: 45px !important; max-height: 45px !important; }
.icono-50{ width: 50px !important; height: 50px !important; min-width: 50px !important; max-width: 50px !important; min-height: 50px !important; max-height: 50px !important; }
.icono-55{ width: 55px !important; height: 55px !important; min-width: 55px !important; max-width: 55px !important; min-height: 55px !important; max-height: 55px !important; }
.icono-60{ width: 60px !important; height: 60px !important; min-width: 60px !important; max-width: 60px !important; min-height: 60px !important; max-height: 60px !important; }
.icono-65{ width: 65px !important; height: 65px !important; min-width: 65px !important; max-width: 65px !important; min-height: 65px !important; max-height: 65px !important; }
.icono-70{ width: 70px !important; height: 70px !important; min-width: 70px !important; max-width: 70px !important; min-height: 70px !important; max-height: 70px !important; }
.icono-75{ width: 75px !important; height: 75px !important; min-width: 75px !important; max-width: 75px !important; min-height: 75px !important; max-height: 75px !important; }
.icono-80{ width: 80px !important; height: 80px !important; min-width: 80px !important; max-width: 80px !important; min-height: 80px !important; max-height: 80px !important; }
.icono-85{ width: 85px !important; height: 85px !important; min-width: 85px !important; max-width: 85px !important; min-height: 85px !important; max-height: 85px !important; }
.icono-90{ width: 90px !important; height: 90px !important; min-width: 90px !important; max-width: 90px !important; min-height: 90px !important; max-height: 90px !important; }
.icono-95{ width: 95px !important; height: 95px !important; min-width: 95px !important; max-width: 95px !important; min-height: 95px !important; max-height: 95px !important; }
.icono-100{ width: 100px !important; height: 100px !important; min-width: 100px !important; max-width: 100px !important; min-height: 100px !important; max-height: 100px !important; }
.icono-125{ width: 125px !important; height: 125px !important; min-width: 125px !important; max-width: 125px !important; min-height: 125px !important; max-height: 125px !important; }
.icono-150{ width: 150px !important; height: 150px !important; min-width: 150px !important; max-width: 150px !important; min-height: 150px !important; max-height: 150px !important; }
.icono-175{ width: 175px !important; height: 175px !important; min-width: 175px !important; max-width: 175px !important; min-height: 175px !important; max-height: 175px !important; }
.icono-200{ width: 200px !important; height: 200px !important; min-width: 200px !important; max-width: 200px !important; min-height: 200px !important; max-height: 200px !important; }

.overflow{ overflow: hidden !important; }
.post, .page {margin: 0px !important;}
.page-content, .entry-content, .entry-summary { margin: 0px !important; }

.truncate-1{ width: 100%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.truncate-2{ overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }
.truncate-3{ overflow: hidden; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; }
.truncate-4{ overflow: hidden; display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; }
.truncate-5{ overflow: hidden; display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; }

.backdrop{ backdrop-filter: blur(4px); -webkit-backdrop-filter: blur(4px);}

.border-none{ border: 0px !important;}

.text-left{text-align: left;}
.text-right{text-align: right;}


/******************* position ********************/
.relative{ position: relative; }
.absolute{ position: absolute; }
.fixed{ position: fixed; }
.sticky{ position: sticky; }
.d-content{ display: contents; }

.lh-10{line-height: 10px !important; }
.lh-11{line-height: 11px !important; }
.lh-12{line-height: 12px !important; }
.lh-13{line-height: 13px !important; }
.lh-14{line-height: 14px !important; }
.lh-15{line-height: 15px !important; }
.lh-16{line-height: 16px !important; }
.lh-17{line-height: 17px !important; }
.lh-18{line-height: 18px !important; }
.lh-19{line-height: 19px !important; }
.lh-20{line-height: 20px !important; }
.lh-21{line-height: 21px !important; }
.lh-22{line-height: 22px !important; }
.lh-23{line-height: 23px !important; }
.lh-24{line-height: 24px !important; }
.lh-25{line-height: 25px !important; }
.lh-30{line-height: 30px !important; }
.lh-35{line-height: 35px !important; }
.lh-40{line-height: 40px !important; }
.lh-100{line-height: 100px !important; }
.lh-140{line-height: 140px !important; }

.opacity-0{ opacity: 0 !important;}
.opacity-9{ opacity: .9 !important;}
.opacity-8{ opacity: .8 !important;}
.opacity-7{ opacity: .7 !important;}
.opacity-6{ opacity: .6 !important;}
.opacity-5{ opacity: .5 !important;}
.opacity-4{ opacity: .4 !important;}
.opacity-3{ opacity: .3 !important;}
.opacity-2{ opacity: .2 !important;}
.opacity-1{ opacity: 1 !important;}


/********************** PAGINA 404 ******************/
.error404 header{ display: none !important; }
.error404 footer{ display: none !important; }
.logo-404{
	max-width: 160px;
	background-color: #fff;
	padding: 10px;
	border-radius: 3px;
}

/********************** PAGINA GRACIAS ******************/
.page-template-gracias footer{
	display: none !important;
}
/**.page-template-gracias header{
	display: none !important;
} **/


.mayusculas{ text-transform: uppercase; }
.minusculas{ text-transform: lowercase; }
.first-letter{ text-transform: lowercase;}
.first-letter::first-letter{ text-transform: uppercase !important;}
.grecaptcha-badge { opacity: 0 !important; }
.pointer { cursor: pointer !important}
.m-0auto{ margin: 0 auto;}
.m-0right{ margin: 0px 0px 0px auto;}
.m-0left{ margin: 0px auto 0px 0px;}
.img-bg{ height: 100%; width: 100%; object-fit: cover; object-position: center;}
img{max-width: 100%;}

.letter-spacing-2{ letter-spacing: 2px;}
.letter-spacing-3{ letter-spacing: 3px;}


.bg-parallax{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}

/********************** Formulario WEB ******************/
.honey{
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 0;
	z-index: -1;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/********************** SLIDER ******************/
.carousel-indicators [data-bs-target] {
    width: 15px;
    height: 15px;
		border-radius: 100%;
}
.carousel-control-next, .carousel-control-prev {
    width: 6%;
}

/********************** Animación pulso whatsapp ******************/
#whatsapp{
	background-color: #28b576;
border: 2px solid #bfffe36e;
position: fixed;
bottom: 15px;
right: 15px;
height: 60px;
width: 60px;
display: flex;
align-items: center;
justify-content: center;
z-index: 3;
border-radius: 100%;
}
.pulse {
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgb(24 219 113 / 73%);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

/********************** Animación pulso ******************/
ul {
  list-style-image: url("starsolid.gif");
}
/********************** Hover Imagenes ******************/
#image-hover .contenido{
	height: 400px;
	z-index: 2;
	position: relative;
	padding: 25px;
	background-color: #000000c7;
	transition: .2s;
	opacity: 0;
}
#image-hover .contenido:hover{
	transition: .2s;
	opacity: 1;
}
#image-hover img{
	position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}
/********************** Footer ******************/
/********************** Formulario de contacto ******************/
.formulario-contacto label{
	color: #b5cbcb;
	font-size: 14px;
}
.formulario-contacto input{
	width: 100%;
background-color: #2c3030;
height: 40px;
border: none !important;
box-shadow: none !important;
outline: none !important;
padding: 20px;
border-bottom: 2px solid #363535 !important;
margin-bottom: 15px;
    color: #fff;
}
.formulario-contacto textarea{
	width: 100%;
background-color: #2c3030;
height: 120px;
min-height: 120px;
max-height: 120px;
border: none !important;
box-shadow: none !important;
outline: none !important;
padding: 20px;
border-bottom: 2px solid #363535 !important;
margin-bottom: 15px;
    color: #fff;
}
footer{
	background-color: var(--footer1);
}
footer .bg-formulario{
	background-color: var(--footer2);
}

.mapa{
	filter: grayscale(0.6);
    opacity: .7;
		transition: .3s;
}
.mapa:hover{
	filter: grayscale(0);
    opacity: 1;
		transition: .3s;
}
footer .enviar button{
	background-color: var(--principal);
	width: 180px;
	color: #fff;
	padding: 10px 20px;
	border: none;
	outline: none;
	box-shadow: none;
	transition: .2s;
}
footer .enviar button:hover{
	background-color: var(--secundario);
	transition: .2s;
}
